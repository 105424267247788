import {isProd} from '@/constants/env';
const ossBase = process.env.VUE_APP_OSS_BASE;
const BASE_URL = isProd ? `${window.location.origin}/.netlify/functions/` : '/mock/';
const generateAPI = (source) => {
  return isProd ? `${BASE_URL}${source}` : `${BASE_URL}${source}.json`;
};

const getAPI = (source) => {
  return isProd ? `/.netlify/functions/${source}` : `${ossBase}/.netlify/functions/${source}`;
};

const API_BASE = process.env.VUE_APP_API_BASE;

export const API = {
  DEVICE: {
    GET_MY_DEVICES: `${API_BASE}/customer/hsna-equipment-papi/v1/getActiveEquipments`,
    GET_TYPES: `${API_BASE}/customer/hsna-triage-papi/v1/getCEProducts`,
    GET_BRANDS: `${API_BASE}/customer/hsna-product-papi/v1/servify/getBrandList`,
    GET_MODELS: `${API_BASE}/customer/hsna-product-papi/v1/servify/getProductList`,
    PROCESS_UNREGISTERED_DEVICE: `${API_BASE}/customer/hsna-product-papi/v1/servify/getUnregisteredProductCode`,
    SAVE: `${API_BASE}/customer/hsna-equipment-papi/v1/addUserEquipment`,
    REMOVE: `${API_BASE}/customer/hsna-equipment-papi/v1/inactivateEquipment`,
    GET_INFO: `${API_BASE}/customer/hsna-equipment-papi/v1/getEquipmentDetails`,
    UPDATE_INFO: `${API_BASE}/customer/hsna-equipment-papi/v1/updateEquipmentDetails`,
    CHECK_90_DAYS: `${API_BASE}/customer/hsna-equipment-papi/v1/getEquipmentEligibility`,
  },
  USER: {
    GET_PRODUCTS: `${API_BASE}/customer/hsna-triage-papi/v1/getDetails`,
    GET_CUSTOMER_DETAILS: `${API_BASE}/customer/hsna-cus-profile-papi/v1/getCustomerDetails`,
    GET_CONTRACT_DETAILS: `${API_BASE}/customer/hsna-sale-papi/v1/getContractDetails`,
    GET_ONLINE_ELIGIBILITY: `${API_BASE}/customer/hsna-servicejob-papi/v1/getOnlineClaimEligibility`,
    DECRYPT_PARAMS: getAPI('param-decrypt'),
    ENCRYPT_PARAMS: getAPI('param-encrypt'),
    CHECK_THIRTY_DAYS: generateAPI('thirty-days-check'),
  },
  CLAIMS: {
    GET_ISSUE_LIST: `${API_BASE}/customer/hsna-triage-papi/v1/getIssueList`,
    GET_TRIAGE_DETAILS: `${API_BASE}/customer/hsna-triage-papi/v1/getTriageDetails`,
    CHECK_ISSUES_UNDER_PLAN: `${API_BASE}/customer/hsna-triage-papi/v1/checkIssuesUnderPlan`,
    SAVE_TRIAGE_DETAILS: `${API_BASE}/customer/hsna-triage-papi/v1/saveTriageDetails`,
    CLAIM_ADJUDICATION: `${API_BASE}/customer/hsna-triage-papi/v1/claimAdjudication`,
    GET_SLOTS: `${API_BASE}/customer/hsna-servifyclaim-papi/v1/getSlotList`,
    GET_SERVICE_AVAILABILITY: `${API_BASE}/customer/hsna-servifyclaim-papi/v1/getServiceAvailability`,
    START_CLAIM_MASTER: `${API_BASE}/customer/hsna-servicejob-papi/v1/createClaimsMaster`,
    GET_CLAIMS_LIST: `${API_BASE}/customer/hsna-servicejob-papi/v1/getServiceJobDetailsCE`,
    START_CLAIM: `${API_BASE}/customer/hsna-servifyclaim-papi/v1/createClaim`,
    RAISE_CLAIM: `${API_BASE}/customer/hsna-servifyclaim-papi/v1/raiseClaim`,
    UPDATE_MASTER_CLAIM: `${API_BASE}/customer/hsna-servicejob-papi/v1/updateClaimsMaster`,
    UPDATE_SERVIFY_CLAIM: `${API_BASE}/customer/hsna-servifyclaim-papi/v1/updateClaimRequestStatus`,
    UPDATE_SERVIFY_REQUEST_DETAILS: `${API_BASE}/customer/hsna-servifyclaim-papi/v1/updateRequestDetails`,
    UPDATE_STATUS_INFO: `${API_BASE}/customer/hsna-servifyWebhooks-papi/v1/updateStatusInfo`,
    GET_BUYOUT_AMOUNT: `${API_BASE}/customer/hsna-servifyclaim-papi/v1/getBuyoutAmount`,
    START: generateAPI('claim-start'),
    HISTORY: generateAPI('service-history'),
    TRIAGE: generateAPI('triage'),
    SAVE_SERVICE_ADDRESS: generateAPI('save-service-address'),
    GET_CLAIM_SERVICE_STATES: generateAPI('claim-address-states'),
    GET_OPTIONS: generateAPI('claim-get-options'),
    GET_CLAIM_ADDRESS: generateAPI('claim-address'),
    GET_DEVICE_DETAILS: generateAPI('claim-get-details'),
    GEL_ZIP: generateAPI('get-zip'),
    GEL_CARRY_IN_LOCATIONS: generateAPI('locations-by-zip'),
    GEL_AVAILABLE_DATES: generateAPI('dates-by-location'),
  },
  WEBHOOK: {
    UPDATE_BUYOUT_INFO: `${API_BASE}/customer/hsna-servifyWebhooks-papi/v1/updateBuyoutInfo`,
  },
  JOB: {
    GET_HISTORY: generateAPI('job-history'),
    GET_INFO: generateAPI('job-info'),
  },
  ADDRESS: {
    GET_TERRITORY: `${API_BASE}/customer/hsna-cus-mgmt-papi/v1/getTerritory`,
    GET_GEO_LOCATION: `${API_BASE}/customer/hsna-cus-mgmt-papi/v1/getGeoLocation`,
  },
};
