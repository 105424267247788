import Vue from 'vue';
import getters from './getters';
import {isProd} from '@/constants/env';
import {API} from '@/constants/api';
import carryInRepair from '@/store/modules/requestServiceModules/carryInRepair';
import homeRepair from '@/store/modules/requestServiceModules/homeRepair';
import {getRequestBody} from '@/utils/request';
import {scBase} from '@/constants/web-redirects';
import generateGuardedAsyncActions from '@/store/generateGuardedAsyncActions';
import {
  statuses as replacementStatuses,
  statusCodesResolver as replacementStatusCodesResolver,
} from '@/constants/replacement/statuses';
import router from '@/router';
import {getStatusCodeFor} from '@/utils/jobStatuses';

const guardedActions = generateGuardedAsyncActions({
  async START_SERVIFY_CLAIM({dispatch, getters, rootState}, payload = {}) {
    const {getStartClaimPayload} = getters;
    const {deviceId, customerRef} = payload;

    await dispatch('user/GET_CUSTOMER_DETAILS', {customerRef}, {root: true});
    await dispatch('deviceInformation/GET_DEVICE_INFO', {id: deviceId, customerRef}, {root: true});
    const reqPayload = getStartClaimPayload(payload);
    const contractDetails = rootState.user.contractDetails;
    const contract = contractDetails?.ContractBasicDetail || {};
    const ProductCode = contract?.ContractType;
    const PartnerCode = contract?.PartnerCode;
    const ContractNo = contract?.ScheduleRef;
    const eligibilityResp = await dispatch(
      'user/GET_ONLINE_ELIGIBILITY',
      {
        customerRef: customerRef,
        productCode: ProductCode,
        contractNo: ContractNo,
        partnerCode: PartnerCode,
        productLine: reqPayload?.request?.createClaimRequest?.productSubCategoryCode,
        optionPosition: '1',
        state: reqPayload?.request?.createClaimRequest?.consumerServiceRequest?.state,
        zipCode: reqPayload?.request?.createClaimRequest?.consumerServiceRequest?.zipcode,
      },
      {root: true}
    );
    reqPayload.request.createClaimRequest.coveredValue =
      eligibilityResp?.data?.response?.claimGetOnlineEligibilityResponse?.OnlineClaims?.benefitRemainingPerClaim;
    const claim = await dispatch('START_CLAIM', {
      payload: reqPayload,
    });
    return claim;
  },
  async INIT_MASTER_CLAIM({dispatch, getters, commit}, payload = {}) {
    if (!payload.requestParams) {
      payload.requestParams = {};
    }

    payload.requestParams.inventoryEquipmentId = payload.inventoryEquipmentId;

    const {encryptedRequestParams, getRedirectUrl} = payload;
    const {getStartClaimMasterPayload, getRaiseClaimPayload} = getters;

    const decryptedRequestParams = await dispatch(
      'user/DECRYPT_PARAMS',
      payload.encryptedRequestParams,
      {root: true}
    );

    const {requestParams} = payload;

    Object.assign(requestParams, decryptedRequestParams);

    const id = await dispatch('START_CLAIM_MASTER', {
      payload: getStartClaimMasterPayload(payload),
    });
    requestParams.externalReferenceID = id;

    if (payload.raiseClaim) {
      const raiseClaimResp = await dispatch('RAISE_CLAIM', {
        payload: getRaiseClaimPayload(payload),
      });
      if (raiseClaimResp?.errorDetails?.errorDetail?.errorDescription) {
        const {customerRef, contractRef} = payload.encryptedRequestParams;
        const query = {customerRef, contractRef};
        const devicesLink = {
          name: 'MyDevices',
          query,
        };
        dispatch(
          'setModalError',
          {
            text: [
              'Seems like we have a trouble processing your request.',
              'Please contact the call center.',
            ],
            cta: [
              {
                text: 'Go to My Devices',
                callback: () => {
                  commit('errorModal/CLEAR_ERROR_MODAL_DATA', null, {root: true});
                  router.push(devicesLink);
                },
              },
            ],
          },
          {root: true}
        );
        return {};
      }
    }

    let paymentUrl = null;

    if (getRedirectUrl) {
      const {redirectUrl, claimsMasterId, customerRef} = await dispatch(
        'user/ENCRYPT_PARAMS',
        {
          customerRef: decryptedRequestParams?.customerRef,
          redirectUrl: getRedirectUrl(id),
          claimsMasterId: id,
        },
        {root: true}
      );

      paymentUrl = `${scBase}/sc/payment/ceclaimspayment?customerref=${
        customerRef || encryptedRequestParams.customerRef
      }&claimsmasterid=${claimsMasterId}&redirectUrl=${redirectUrl}`;
    }

    return {id, paymentUrl};
  },

  async FETCH_GEO_LOCATION(context) {
    const {getters, commit} = context;

    const {houseNo, streetName, flatNoWithApt, city, state, zipcode, country} = getters;

    const payload = getRequestBody({
      requestName: 'getGeoLocationRequest',
      requestParams: {
        houseNo,
        streetName,
        flatNo: flatNoWithApt,
        city,
        state,
        country,
        zipcode,
      },
    });

    const response = await context.rootState.actionsDecorator({
      store: context,
      path: API.ADDRESS.GET_GEO_LOCATION,
      method: 'post',
      payload,
    });

    const geoLocationResponse = response.data?.response?.getGeoLocationResponse || {};

    const {latitude, longitude} = geoLocationResponse;
    const geoLocationFetched = latitude && longitude;
    if (geoLocationFetched) {
      commit('SET_GEO_LOCATION', {latitude, longitude});
    }

    return geoLocationFetched;
  },

  async GET_LOCATION_BY_ADDRESS({rootState}, payload) {
    const {city, flatNo, houseNo, state, streetName, zipcode} = payload;
    const getGeoLocationRequestPayload = getRequestBody({
      requestName: 'getGeoLocationRequest',
      requestParams: {
        houseNo,
        streetName,
        flatNo,
        city,
        state,
        country: 'USA',
        zipcode,
      },
    });

    const response = await rootState.actionsDecorator({
      store: 'requestService',
      path: API.ADDRESS.GET_GEO_LOCATION,
      method: 'post',
      payload: getGeoLocationRequestPayload,
    });

    const geoLocationResponse = response.data?.response?.getGeoLocationResponse || {};

    const {latitude, longitude} = geoLocationResponse;

    return {
      latitude,
      longitude,
    };
  },

  async UPDATE_MASTER_CLAIM(context, {claim, params, customerRef, contractRef, skipDecryption}) {
    const {dispatch, rootGetters} = context;

    const {
      claimsMasterId,
      serviceJobStatus,
      serviceJobStatusName,
      serviceJobSubStatus,
      serviceJobSubStatusName,
    } = claim;
    const user = rootGetters['user/fullName'];

    if (!skipDecryption) {
      ({customerRef, contractRef} = await dispatch(
        'user/DECRYPT_PARAMS',
        {
          customerRef,
          contractRef,
        },
        {root: true}
      ));
    }

    const payload = getRequestBody({
      requestName: 'updateClaimsMasterRequest',
      requestParams: {
        claimsMasterID: claimsMasterId,
        user,

        customerRef,
        contractRef,

        serviceJobStatus,
        serviceJobStatusName,

        serviceJobSubStatus,
        serviceJobSubStatusName,

        ...params,
      },
    });

    return context.rootState.actionsDecorator({
      store: context,
      path: API.CLAIMS.UPDATE_MASTER_CLAIM,
      method: 'post',
      payload,
    });
  },

  async UPDATE_SERVIFY_CLAIM(context, {claim, params}) {
    const {serviceJobID, claimsMasterId} = claim;

    const payload = getRequestBody({
      requestName: 'updateClaimRequestStatusRequest',
      requestParams: {
        externalReferenceID: claimsMasterId,
        referenceID: serviceJobID,

        ...params,
      },
    });

    return context.rootState.actionsDecorator({
      store: context,
      path: API.CLAIMS.UPDATE_SERVIFY_CLAIM,
      method: 'post',
      payload,
    });
  },

  async ACCEPT_REPLACEMENT_OPTION({dispatch}, payload) {
    const newStatusCode = replacementStatuses.replacementOptionAccepted.code;
    const {claim, customerRef, contractRef, replacementOption} = payload;

    const response = await dispatch('UPDATE_SERVIFY_CLAIM', {
      claim,
      params: {
        replacementOption,
        statusCode: newStatusCode,
      },
    });

    const optionAccepted = response.data?.response?.outcome?.resultDescription === 'Success';

    // NOTE: Remove master claim update. Rely on Servify webhook
    // if (optionAccepted) {
    //   await dispatch('UPDATE_MASTER_CLAIM', {
    //     claim,
    //     customerRef,
    //     contractRef,
    //     params: {
    //       serviceJobStatus: 'ACCEPTED',
    //       serviceJobStatusName: 'Accepted',

    //       serviceJobSubStatus: newStatusCode,
    //       serviceJobSubStatusName: 'Replacement option accepted',
    //     },
    //   });
    // }

    return optionAccepted;
  },

  async CANCEL_SERVIFY_CLAIM(store, payload) {
    const {dispatch, rootGetters, rootState} = store;
    const statusCode = getStatusCodeFor('serviceCancelled');
    const {claim, reason, customerRef, contractRef} = payload;
    const {claimsMasterId, serviceJobID, serviceJobType} = claim;

    if (reason === 'BUYOUT_CANCEL' && serviceJobType === 'CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE') {
      // TODO: Claim cancellation through updateBuyoutInfo. Reference: DPD-4225.
      const user = rootGetters['user/fullName'];
      const updateBuyoutInfoRequestPayload = getRequestBody({
        user,
        requestName: 'updateBuyoutInfoRequest',
        requestParams: {
          hsRefID: claimsMasterId,
          servifyRefID: serviceJobID,
          status: 'Replacement declined',
          statusCode: 'HS003',
        },
      });
      await rootState.actionsDecorator({
        store,
        path: API.WEBHOOK.UPDATE_BUYOUT_INFO,
        method: 'post',
        payload: updateBuyoutInfoRequestPayload,
      });

      const query = {customerRef, contractRef};
      await router.push({
        name: 'MyDevices',
        query,
      });
    } else {
      const response = await dispatch('UPDATE_SERVIFY_CLAIM', {
        claim,
        params: {
          cancellationReasonCode: reason || 'CUSTOMERCANCEL',
          cancellationRemarks: reason || 'customer cancelled with SP',
          statusCode,
        },
      });

      // "errorCode":"SM.WTR.001","errorDescription":"Request is already cancelled:"
      const claimCancelled =
        response.data?.response?.outcome?.resultDescription === 'Success' ||
        response.data?.response?.updateClaimRequestStatusResponse?.errorDetails?.errorDetail
          ?.errorCode === 'SM.WTR.001';

      return claimCancelled;
    }
  },

  async ACCEPT_BUYOUT({dispatch}, masterId) {
    return dispatch('UPDATE_STATUS_INFO', {
      masterId,
      serviceJobType: 'Buyout - Accept',
    });
  },

  async REJECT_BUYOUT({dispatch}, masterId) {
    return dispatch('BUYOUT_REJECT', {
      masterId,
    });
  },
});

const modules = {
  carryInRepair,
  homeRepair,
};

const getEmptyDetails = () => ({
  time: null,
  description: null,
});

const getEmptyGeoLocation = () => ({
  latitude: null,
  longitude: null,
});

const state = {
  ...guardedActions.state,
  useCustomAddress: false,
  brandCode: '',
  productCode: '',
  productLine: '',
  productLineName: '',
  type: '',
  typeCode: '',
  issues: [],
  issue: '',
  triageReferenceId: '',
  address: null,
  adjudicationResponse: null,
  availabilityResponses: null,
  details: getEmptyDetails(),
  geoLocation: getEmptyGeoLocation(),
  paymentLoader: false,
};

const actions = {
  ...guardedActions.actions,
  ['START_CLAIM_MASTER']: (store, {payload}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.START_CLAIM_MASTER,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res?.data?.response?.createClaimsMasterResponse?.claimsMasterId);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['START_CLAIM']: (store, {payload}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.START_CLAIM,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res?.data?.response?.createClaimResponse);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['RAISE_CLAIM']: (store, {payload}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.RAISE_CLAIM,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res?.data?.response?.raiseClaimResponse);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['UPDATE_STATUS_INFO']: (store, {masterId, ...params}) => {
    const user = store.rootGetters['user/fullName'];

    const payload = getRequestBody({
      user,
      requestName: 'updateStatusInfoRequest',
      requestParams: {
        hsRefID: masterId,
        statusCode: 'New',
        status: 'New',
        ...params,
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.UPDATE_STATUS_INFO,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res?.data?.response?.updateStatusInfoResponse);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['BUYOUT_REJECT']: (store, {masterId, ...params}) => {
    const user = store.rootGetters['user/fullName'];

    const payload = getRequestBody({
      user,
      requestName: 'updateStatusInfoRequest',
      requestParams: {
        hsRefID: masterId,
        statusCode: 'New',
        status: 'New',
        serviceJobType: 'Buyout - Reject',
        ...params,
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.UPDATE_STATUS_INFO,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res?.data?.response?.updateStatusInfoResponse);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_TRIAGE_DETAILS']: (store, {productSubCategoryCode, brandCode, productCode, issueCodes}) => {
    return new Promise((resolve, reject) => {
      const payload = getRequestBody({
        requestName: 'getTriageDetailsRequest',
        requestParams: {
          productSubCategoryCode,
          brandCode,
          productCode,
          issueCodes,
          countryCode: 'US',
        },
      });
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_TRIAGE_DETAILS,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  ['CHECK_ISSUES_UNDER_PLAN']: (store) => {
    const {productLine, issue, typeCode} = store.state;

    return new Promise((resolve, reject) => {
      const payload = getRequestBody({
        requestName: 'checkIssuesUnderPlanRequest',
        requestParams: {
          countryCode: 'US',
          productSubCategoryCode: productLine,
          planCode: 'HMSRV12MEWADLD', // TODO: Move to env var.
          unresolvedIssues: [{issueCode: issue, issueCategoryCode: typeCode}],
        },
      });
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.CHECK_ISSUES_UNDER_PLAN,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  ['SAVE_TRIAGE_DETAILS']: (store, {issueCode, issueTriageCode, triageAnswers}) => {
    return new Promise((resolve, reject) => {
      const payload = getRequestBody({
        requestName: 'saveTriageDetailsRequest',
        requestParams: {
          countryCode: 'US',
          triageDetails: [
            {
              issueCode,
              issueTriageCode,
              triageAnswers,
            },
          ],
        },
      });
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.SAVE_TRIAGE_DETAILS,
          method: 'post',
          payload,
        })
        .then((res) => {
          const {commit} = store;
          commit(
            'SAVE_TRIAGE_REFERENCE_ID',
            res?.data?.response?.saveTriageDetailsResponse?.triageReferenceID
          );
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  async CLAIM_ADJUDICATION(store, customerRef) {
    store.commit('SET_ADJUDICATION_RESPONSE', null);
    store.commit('SET_AVAILABILITY_RESPONSE', null);
    const contractDetails = store.rootState.user.contractDetails;
    const customerDetails = store.rootState.user.customerDetails;
    const customerAddress = customerDetails?.AddressDetail || {};
    const contract = contractDetails?.ContractBasicDetail || {};
    const ProductCode = contract?.ContractType;
    const PartnerCode = contract?.PartnerCode;
    const ContractNo = contract?.ScheduleRef;
    const {productLine, useCustomAddress, address} = store.state;
    const eligibilityResp = await store.dispatch(
      'user/GET_ONLINE_ELIGIBILITY',
      {
        customerRef: customerRef,
        productCode: ProductCode,
        contractNo: ContractNo,
        partnerCode: PartnerCode,
        productLine: productLine,
        optionPosition: '1',
        state: useCustomAddress ? address?.state : customerDetails?.addressLine5,
        zipCode: useCustomAddress ? address?.zip : customerDetails?.zipCode,
      },
      {root: true}
    );
    return new Promise((resolve, reject) => {
      const {productCode, brandCode, productLine, triageReferenceId, serialNumber, issue} =
        store.state;
      const payload = getRequestBody({
        requestName: 'claimAdjudicationRequest',
        requestParams: {
          productCode,
          brandCode,
          productSubCategoryCode: productLine, // LS or other, device type code
          imeiOrSerial: serialNumber,
          currencyCode: 'USD',
          coveredValue:
            '' +
            eligibilityResp?.data?.response?.claimGetOnlineEligibilityResponse?.onlineClaims
              ?.benefitRemainingPerClaim,
          planCode: 'HMSRV12MEWADLD', // TODO: Move to env var.
          issueCodes: {
            issueCode: [issue],
          },
          countryCode: 'US',
          triageReferenceID: triageReferenceId, // TODO: Make sure spelling is correct.
        },
      });
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.CLAIM_ADJUDICATION,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit('SET_ADJUDICATION_RESPONSE', res?.data?.response?.claimAdjudicationResponse);
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_ISSUE_LIST']: (
    store,
    {productSubCategoryCode, brandCode, productCode, productLineName, serialNumber}
  ) => {
    const {commit} = store;
    commit('SET_PRODUCT_LINE', productSubCategoryCode);
    commit('SET_PRODUCT_LINE_NAME', productLineName);
    commit('SET_BRAND_CODE', brandCode);
    commit('SET_PRODUCT_CODE', productCode);
    commit('SET_SERIAL_NUMBER', serialNumber);
    const payload = getRequestBody({
      requestName: 'getIssueListRequest',
      requestParams: {
        productSubCategoryCode,
        brandCode,
        productCode,
        countryCode: 'US',
      },
    });
    payload.sourceSystemRefId = '1243dg574h'; // TODO: Make sure this param is redundant.
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_ISSUE_LIST,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['SET_ISSUE_CATEGORY']: ({commit}, payload) => {
    commit('SET_ISSUE_CATEGORY', payload);
  },
  ['SET_ISSUE']: ({commit}, payload) => {
    commit('SET_ISSUE', payload);
  },
  ['GET_CLAIM_ADDRESS']: (store) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_CLAIM_ADDRESS,
          method: 'get',
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_OPTIONS']: (store, {id}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_OPTIONS,
          method: 'get',
          payload: {
            params: {
              id,
            },
          },
        })
        .then((res) => {
          store.commit('SET_ADJUDICATION_RESPONSE', res?.data?.response?.claimAdjudicationResponse);
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_AVAILABILITY']: (context, {mode, params = {}}) => {
    const {make, model, productLine} = context.rootState.deviceInformation;
    const {issue} = context.state;
    const {zipcode, city, state, houseNo, flatNoWithApt, streetName} = context.getters;

    const address = {
      zipCode: zipcode,
      city,
      state,
      houseNo,
      flatNo: flatNoWithApt,
      streetName,

      // TODO: remove once(if?) no longer needed
      streetAddress: streetName,
    };

    const payload = getRequestBody({
      requestName: 'getServiceAvailabilityRequest',
      requestParams: {
        mode,
        ...address,

        productCode: model,
        brandCode: make,
        productSubCategoryCode: productLine,
        issueCodes: [issue],

        countryCode: 'US',
        planCode: 'HMSRV12MEWADLD', // TODO: Move to env var.

        ...params,
      },
    });
    return new Promise((resolve, reject) => {
      context.rootState
        .actionsDecorator({
          store: context,
          path: API.CLAIMS.GET_SERVICE_AVAILABILITY,
          method: 'post',
          payload,
        })
        .then((res) => {
          console.log('SET_AVAILABILITY_RESPONSE---');
          context.commit('SET_AVAILABILITY_RESPONSE', {
            mode,
            data: res?.data?.response?.getServiceAvailabilityResponse,
          });
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_CLAIM_SERVICE_STATES']: (store) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_CLAIM_SERVICE_STATES,
          method: 'get',
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['SAVE_CLAIM_SERVICE_ADDRESS']: (store, {address, state, city, zip, apartment}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.SAVE_SERVICE_ADDRESS,
          method: isProd ? 'post' : 'get',
          payload: {
            params: {
              address,
              state,
              city,
              zip,
              apartment,
            },
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_HISTORY']: async (store) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.HISTORY,
          method: 'get',
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_DEVICE_DETAILS']: async (store, {id}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_DEVICE_DETAILS,
          method: 'get',
          payload: {
            params: {
              id,
            },
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  ...guardedActions.mutations,
  ['SET_PAYMENT_PROGRESS']: (state, val) => {
    state.paymentLoader = val;
  },
  ['SET_ISSUE_CATEGORY']: (state, {type, typeCode, issues}) => {
    state.type = type;
    state.typeCode = typeCode;
    state.issues = issues;
  },
  ['SET_PRODUCT_LINE']: (state, code) => {
    state.productLine = code;
  },
  ['SET_PRODUCT_LINE_NAME']: (state, name = '') => {
    state.productLineName = name;
  },
  ['SET_BRAND_CODE']: (state, code) => {
    state.brandCode = code;
  },
  ['SET_PRODUCT_CODE']: (state, code) => {
    state.productCode = code;
  },
  ['SET_SERIAL_NUMBER']: (state, sn) => {
    state.serialNumber = sn;
  },
  ['SET_ISSUE']: (state, issue) => {
    state.issue = issue;
  },
  ['SAVE_TRIAGE_REFERENCE_ID']: (state, id) => {
    state.triageReferenceId = id;
  },
  ['SET_USE_CUSTOM_ADDRESS']: (state, useCustomAddress) => {
    state.useCustomAddress = useCustomAddress;
  },
  ['SET_ADDRESS_ATTRIBUTE']: (state, data) => {
    if (!state.address) state.address = {};
    const value = data.value?.trim() || '';
    Vue.set(state.address, data.field, value);
  },
  ['SET_ADJUDICATION_RESPONSE']: (state, data) => {
    state.adjudicationResponse = data;
  },
  ['SET_AVAILABILITY_RESPONSE']: (state, data) => {
    if (!data) {
      state.availabilityResponses = data;
      return;
    }
    if (!state.availabilityResponses) state.availabilityResponses = {};
    Vue.set(state.availabilityResponses, data.mode, data.data);
  },
  SET_DETAILS: (state, details) => {
    state.details = details || getEmptyDetails();
  },
  SET_GEO_LOCATION: (state, geoLocation) => {
    state.geoLocation = geoLocation || getEmptyGeoLocation();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
