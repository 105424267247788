import {v4 as uuidv4} from 'uuid';

export const getRequestBody = ({requestName, requestParams, user}) => {
  return {
    request: {
      user: user || undefined,
      sourceSystemName: 'WEB',
      sourceSystemVersion: '2.0',
      sourceSystemRefId: '123', // TODO: Required. But so far is a constant for all requests.
      transactionId: uuidv4() + '',
      [requestName]: {
        ...requestParams,
        context: 'WEB',
      },
    },
  };
};
