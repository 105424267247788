<template>
  <div class="select-contract__option">
    <hs-radio
      :id="`plan_${contract.scheduleRef}`"
      :value="contract.scheduleRef"
      name="select_contract"
      @change="$emit('setContract', contract.scheduleRef)"
      :selected="selected"
    >
      <div class="select-contract__description">
        {{ contractName }}
        <div class="select-contract__info" v-if="showFullDescription">
          {{ contractInformation }}
        </div>
      </div>
    </hs-radio>
  </div>
</template>

<script>
export default {
  props: ['contract', 'selected', 'showFullDescription'],
  data() {
    return {
      contractInfoName: null,
      contractFromDate: null,
      contractToDate: null,
    };
  },
  async created() {
    const scheduleRef = this.contract.scheduleRef;
    let contractInfo = await this.$store.dispatch('user/GET_CONTRACT', {
      scheduleRef,
    });

    this.contractInfoName =
      contractInfo?.data?.response?.getContractDetailsResponse?.OptionDetail[0]?.Name;
    this.contractFromDate =
      contractInfo?.data?.response?.getContractDetailsResponse?.ContractBasicDetail?.VersionFromDate;
    this.contractToDate =
      contractInfo?.data?.response?.getContractDetailsResponse?.ContractBasicDetail?.VersionToDate;
  },
  computed: {
    contractInformation() {
      return `Plan number ${this.contract.scheduleRef} ${this.coverage}`;
    },
    contractName() {
      return this.contractInfoName;
    },
    coverage() {
      if (this.contractFromDate && this.contractToDate) {
        return `| ${this.contractFromDate} - ${this.contractToDate}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.select-contract {
  &__option {
    padding: 20px 0;
    font-size: 18px;
    line-height: 21px;
    border-top: 1px solid $gray;
  }
}
</style>
<style scoped lang="scss">
.select-contract {
  &__option {
    > div {
      flex: 1;
    }
  }
  &__description {
    color: $black;
  }
  &__info {
    margin-top: 2px;
    color: $mediumGray;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>


<style lang="scss">
/* TODO: Consider refactoring this cascade to BEM. */
.select-contract__option {
  .hs-radio.pretty {
    display: block;
    margin-right: 25px;
    white-space: normal;
    .state label {
      position: relative;
      display: block;
      padding-right: 40px;
      text-indent: 0;
      &::before,
      &::after {
        top: 50%;
        right: 0;
        left: auto;
        transform: translate(0, -50%);
      }
      &::after {
        right: 4px;
        width: 8px;
        height: 8px;
      }
    }
  }
}
</style>
