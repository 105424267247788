<template>
  <div class="app" @mousemove="setLogoutTimer" @click="setLogoutTimer" @keydown="setLogoutTimer">
    <Header />
    <router-view />
    <!-- <div class="unauthorized" v-if="!authorized">Unauthorized user.</div> -->
    <Footer />
    <ErrorModal />
    <AuthErrorModal />
    <StillHereModal
      v-if="showStillHereModal"
      :logout-time="logoutTime"
      @hide="hideStillHereModal"
    />
    <SelectContractModal v-if="showSelectContractModal && !showContractsLoading" />
  </div>
</template>

<script>
import {logoutDelay} from '@/constants/logout_time_in_ms';
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import ErrorModal from '@/components/shared/ErrorModal';
import AuthErrorModal from '@/components/shared/AuthErrorModal';
import StillHereModal from '@/components/shared/StillHereModal';
import SelectContractModal from '@/components/shared/SelectContractModal';
import {mapState} from 'vuex';

export default {
  metaInfo: {
    title: 'Omnichannel Self Service',
    htmlAttrs: {
      lang: 'en',
    },
  },
  components: {
    Header,
    Footer,
    ErrorModal,
    AuthErrorModal,
    StillHereModal,
    SelectContractModal,
  },
  data() {
    return {
      logoutTime: +new Date() + logoutDelay,
      showStillHereModal: false,
      stillHereTimer: null,
      globalHandlerTimer: null,
    };
  },
  computed: {
    ...mapState('user', [
      'customerDetails',
      'showSelectContractModal',
      'contractDetails',
      'onlineClaimEligible',
      'contracts',
    ]),
    authorized() {
      return this.$auth.isAuthenticated;
    },
    customerContracts() {
      return this.customerDetails?.contract || {};
    },
    customerAddress() {
      let AddressDetail = {
        HouseNo: this.customerDetails?.houseNo,
        AddressLine2: this.customerDetails?.addressLine2,
        State: this.customerDetails?.addressLine5,
        ZipCode: this.customerDetails?.zipCode,
        Country: this.customerDetails?.country,
        City: this.customerDetails?.addressLine4,
      };

      return AddressDetail || {};
    },
    showContractsLoading() {
      return this.contracts.length === 0;
    },
  },
  watch: {
    $route: {
      async handler() {
        let decryptedData = {};
        if (this.$route.query.customerRef && !this.customerDetails) {
          const {customerRef, contractRef} = this.$route.query;
          decryptedData = await this.$store.dispatch('user/DECRYPT_PARAMS', {
            customerRef,
            contractRef,
          });
          await this.$store.dispatch('user/GET_CUSTOMER_DETAILS', {
            customerRef: decryptedData?.customerRef,
          });
        }
        if (this.$route.query.contractRef && this.onlineClaimEligible === null) {
          if (!decryptedData.contractRef) {
            const {customerRef, contractRef} = this.$route.query;
            decryptedData = await this.$store.dispatch('user/DECRYPT_PARAMS', {
              customerRef,
              contractRef,
            });
          }
          let contract = Array.isArray(this.contracts) ? this.contracts[0] || {} : this.contracts;
          const currentContract = this.contracts.find(
            (c) => c?.scheduleRef === decryptedData.contractRef
          );
          if (currentContract) {
            contract = currentContract;
          }
          if (contract.scheduleRef) {
            await this.$store.dispatch('user/GET_CONTRACT_DETAILS', {
              scheduleRef: contract?.scheduleRef,
            });
            const ctrct = this.contractDetails?.ContractBasicDetail || {};
            const ProductCode = ctrct?.ContractType;
            const PartnerCode = ctrct?.PartnerCode;
            const ContractNo = ctrct?.ScheduleRef;
            await this.$store.dispatch('user/GET_ONLINE_ELIGIBILITY', {
              customerRef: decryptedData?.customerRef,
              productCode: ProductCode,
              partnerCode: PartnerCode,
              contractNo: ContractNo,
              optionPosition: '1',
              state: this.customerAddress?.State,
              zipCode: this.customerAddress?.ZipCode,
            });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    hideStillHereModal() {
      this.showStillHereModal = false;
      localStorage.setItem('sessionExpiredTime', +new Date() + logoutDelay);
    },
    setLogoutTimer() {
      if (!this.showStillHereModal) {
        if (this.globalHandlerTimer) clearTimeout(this.globalHandlerTimer);
        this.globalHandlerTimer = setTimeout(() => {
          const newDate = +new Date() + logoutDelay;
          localStorage.setItem('sessionExpiredTime', newDate);
          this.logoutTime = newDate;
          this.setStillHereModalTimer();
        }, 1000);
      }
    },
    setStillHereModalTimer() {
      if (this.stillHereTimer) clearTimeout(this.stillHereTimer);
      this.stillHereTimer = setTimeout(() => {
        if (this.authorized) this.showStillHereModal = true;
      }, (this.logoutTime - new Date()) / 2);
    },
  },
  mounted() {
    this.setLogoutTimer();
    window.addEventListener('scroll', this.setLogoutTimer);
    window.addEventListener('storage', () => {
      if (+localStorage.getItem('sessionExpiredTime')) {
        this.logoutTime = +localStorage.getItem('sessionExpiredTime');
        this.setStillHereModalTimer();
        if (document.hidden) this.showStillHereModal = false;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.unauthorized {
  width: calc(100% - (10px * 2));
  max-width: 1160px;
  margin: 0 auto;
  padding: 60px 0;
  color: #000000;
  font-weight: 700;
  font-size: 24px;
}
</style>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  line-height: 1.4em;
  text-decoration: none;
}
*::-moz-focus-inner {
  border: 0;
}
*:focus {
  outline: none;
}
*[hidden] {
  display: none;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'museo-sans', 'Trebuchet MS', sans-serif;
  background: $backgroundColor;
}
input,
select,
textarea {
  font-family: 'museo-sans', 'Trebuchet MS', sans-serif;
}
u {
  text-decoration: underline;
}
button {
  font-size: inherit;
  font-family: inherit;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
a:focus {
  color: inherit;
  text-decoration: none;
}
a:focus,
button:focus {
  outline: 0;
}
textarea {
  overflow: auto;
  resize: none;
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
</style>
