import {API} from '@/constants/api';
import isPlanActive from '@/constants/activePlanCodes';
import filterCEContracts from '@/utils/ceContracts';
import {getRequestBody} from '@/utils/request';
import generateGuardedAsyncActions from '@/store/generateGuardedAsyncActions';

const guardedActions = generateGuardedAsyncActions({
  async GET_TERRITORY(context, zip) {
    const payload = getRequestBody({
      requestName: 'territoryLookupRequest',
      requestParams: {
        zipCode: zip,
      },
    });

    const {data} = await context.rootState.actionsDecorator({
      store: context,
      payload,
      path: API.ADDRESS.GET_TERRITORY,
      method: 'post',
    });

    return data.response?.territoryLookupResponse;
  },
});

const state = {
  ...guardedActions.state,
  thirtyDaysExpired: null,
  customerDetails: null,
  contractDetails: null,
  customerProducts: null,
  cePlanCodes: [],
  premierPlanCodes: [],
  supportSmartphoneClaim: null,
  planCode: null,
  onlineClaimEligible: null,
  showSelectContractModal: false,
  contracts: [],
};

const getters = {
  planCode: ({planCode}) => planCode,

  partnerCode: ({contracts}) => {
    const contract = contracts[0];
    if (!contract) {
      return null;
    }
    const {partnerCode} = contract;
    console.log('partnerCode-' + partnerCode);
    return partnerCode;
  },

  fullName: ({customerDetails = {}}) => {
    const {NameDetail} = customerDetails;
    //return `${NameDetail?.FirstName} ${NameDetail?.LastName}`.trim();
    return `${customerDetails.initials} ${customerDetails.surname}`.trim();
  },

  mobileNumber: ({customerDetails}) => {
    //const contactDetail = customerDetails?.ContactDetail;
    const contactDetail = customerDetails;

    let phone;

    const phoneFields = ['mobilePhone', 'homePhone', 'workPhone'];

    for (const fieldName of phoneFields) {
      phone = contactDetail?.[fieldName];
      if (phone) {
        break;
      }
    }

    return phone;
  },

  email: ({customerDetails}) => {
    return customerDetails?.email || '';
  },

  productLinesClaims: ({customerProducts}) => {
    const claimClass = customerProducts?.product?.claimClasses?.claimclass || [];
    const filteredDetails = claimClass.filter((details) => details.featurCodeDetail?.featureCode);

    const productLinesClaimsIndexEntries = filteredDetails.map((details) => {
      const {featureCode} = details.featurCodeDetail;
      return featureCode.map((feature) => [feature.productLine, details]);
    });

    const productLinesClaims = Object.fromEntries([].concat(...productLinesClaimsIndexEntries));

    return productLinesClaims;
  },
};

const actions = {
  ...guardedActions.actions,
  ['DECRYPT_PARAMS']: (store, params) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.DECRYPT_PARAMS,
          method: 'post',
          payload: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['ENCRYPT_PARAMS']: (store, params) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.ENCRYPT_PARAMS,
          method: 'post',
          payload: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_CUSTOMER_DETAILS']: async (store, {customerRef}) => {
    const payload = getRequestBody({
      requestName: 'getCustomerDetailsRequest',
      requestParams: {
        customerRef: customerRef,
        resultSet: 'EXTENDED',
        returnContracts: true,
        returnPaymentDetails: true,
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_CUSTOMER_DETAILS,
          method: 'post',
          payload,
        })
        .then(async (res) => {
          const details = res?.data?.response?.getCustomerDetailsResponse;
          store.commit('SET_CUSTOMER_DETAILS', details);
          let contracts = [];
          if (Array.isArray(details?.contract)) {
            contracts = details.contract;
          } else if (details?.contract?.scheduleRef) {
            contracts = [details.contract];
          }

          const activeContracts = contracts.filter((c) => isPlanActive(c?.status));
          Promise.all(filterCEContracts(activeContracts, store))
            .then((ceContracts) => {
              const filteredPlans = ceContracts.filter((c) => !!c);
              store.commit('SET_CUSTOMER_CONTRACTS', filteredPlans);
              store.commit(
                'SET_PREMIER_PLAN_CODES',
                filteredPlans.filter((p) => p.type === 'premier').map((p) => p.contractType)
              );
              store.commit(
                'SET_CE_PLAN_CODES',
                filteredPlans.map((p) => p.contractType)
              );
            })
            .finally(() => {
              resolve();
            });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_CONTRACT_DETAILS']: async (store, {scheduleRef}) => {
    const payload = getRequestBody({
      requestName: 'getContractDetailsRequest',
      requestParams: {
        ScheduleRef: scheduleRef,
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_CONTRACT_DETAILS,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit('SET_CONTRACT_DETAILS', res?.data?.response?.getContractDetailsResponse);

          const optionDetail = store.state?.contractDetails?.OptionDetail;
          if (optionDetail[0]?.RMAgentCoverFrom) {
            const coverFromDateArr = optionDetail[0]?.RMAgentCoverFrom?.split('/');
            if (coverFromDateArr[2]?.length == 2) coverFromDateArr[2] = '20' + coverFromDateArr[2];
            const nowDate = new Date();
            const coverFromDate = new Date(
              +coverFromDateArr[2],
              coverFromDateArr[0] - 1,
              +coverFromDateArr[1],
              0,
              0,
              0,
              0
            );
            console.log('coverFromDate');
            console.log(+nowDate < +coverFromDate);

            if (+nowDate < +coverFromDate) {
              store.commit('SET_THIRTY_DAYS', false);
            }
          }

          const contractPlanCode = store.state?.contractDetails?.ContractBasicDetail?.ContractType;

          if (contractPlanCode && !store.state?.premierPlanCodes?.includes(contractPlanCode)) {
            store.commit('SET_SMARTPHONE_SUPPORT', false);
          }

          if (contractPlanCode) {
            store.commit('SET_PLAN_CODE', contractPlanCode);
          }
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_CONTRACT']: async (store, {scheduleRef}) => {
    const payload = getRequestBody({
      requestName: 'getContractDetailsRequest',
      requestParams: {
        ScheduleRef: scheduleRef,
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_CONTRACT_DETAILS,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_PRODUCTS']: async (store, data) => {
    const payload = getRequestBody({
      requestName: 'getProductDetailsRequest',
      requestParams: {
        product: [{...data}],
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_PRODUCTS,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit(
            'SET_CUSTOMER_PRODUCTS',
            res?.data?.response?.getProductDetailsResponse?.products
          );
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_ONLINE_ELIGIBILITY']: async (store, data) => {
    const payload = getRequestBody({
      requestName: 'claimGetOnlineEligibilityRequest',
      requestParams: {...data},
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_ONLINE_ELIGIBILITY,
          method: 'post',
          payload,
        })
        .then((res) => {
          if (
            res?.data?.response?.claimGetOnlineEligibilityResponse?.onlineClaims
              ?.onlineClaimEligibility === true ||
            res?.data?.response?.claimGetOnlineEligibilityResponse?.onlineClaims
              ?.onlineClaimEligibility === 'true'
          ) {
            store.commit('SET_ONLINE_ELIGIBILITY', true);
          } else {
            store.commit('SET_ONLINE_ELIGIBILITY', false);
          }
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['SHOW_SELECT_CONTRACT_MODAL']: async ({commit}) => {
    commit('SET_CONTRACT_MODAL_VISIBILITY', true);
  },
  ['HIDE_SELECT_CONTRACT_MODAL']: async ({commit}) => {
    commit('SET_CONTRACT_MODAL_VISIBILITY', false);
  },
};

const mutations = {
  ...guardedActions.mutations,
  ['SET_THIRTY_DAYS']: (state, value) => {
    state.thirtyDaysExpired = value;
  },
  ['SET_CUSTOMER_DETAILS']: (state, data) => {
    state.customerDetails = data;
  },
  ['SET_CUSTOMER_CONTRACTS']: (state, data) => {
    state.contracts = data;
  },
  ['SET_CONTRACT_DETAILS']: (state, data) => {
    state.contractDetails = data;
  },
  ['SET_CE_PLAN_CODES']: (state, data) => {
    state.cePlanCodes = data;
  },
  ['SET_PREMIER_PLAN_CODES']: (state, data) => {
    state.premierPlanCodes = data;
  },
  ['SET_CUSTOMER_PRODUCTS']: (state, data) => {
    state.customerProducts = data;
  },
  ['SET_SMARTPHONE_SUPPORT']: (state, value) => {
    state.supportSmartphoneClaim = value;
  },
  ['SET_PLAN_CODE']: (state, value) => {
    state.planCode = value;
  },
  ['SET_ONLINE_ELIGIBILITY']: (state, value) => {
    state.onlineClaimEligible = value;
  },
  ['SET_CONTRACT_MODAL_VISIBILITY']: (state, show) => {
    state.showSelectContractModal = show;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
